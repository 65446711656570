
























































import Vue from "vue";
import TheSnackbar from "@/components/TheSnackbar.vue";
import { GetResponseErrors } from "@/common/utils";
import { PostPasswordReset } from "@/api/auth";
export default Vue.extend({
  name: "password-recovery",
  components: { TheSnackbar },
  data() {
    return {
      isLoading: false,
      isRedirectDialogVisible: false,
      form: {
        password: "",
        repeat_password: "",
        code: "",
      },
    };
  },
  mounted() {
    // this.form.phone = (this.$route.query as any)?.phone ?? "";
  },
  methods: {
    getIsValid() {
      var isValid = true;
      if (this.form.password != this.form.repeat_password) {
        isValid = false;
        (this.$refs.snackbar as any).show("Las contraseñas no coinciden");
      }
      if (this.form.code.length < 6) {
        isValid = false;
        (this.$refs.snackbar as any).show(
          "El código es inválido."
        );
      }
      return isValid;
    },
    async handleSubmit() {
      if (!this.getIsValid()) return;
      this.isLoading = true;
      try {
        await PostPasswordReset(this.form);
        this.isRedirectDialogVisible = true;
      } catch (err) {
        (this.$refs.snackbar as any).show(GetResponseErrors(err));
      }
      this.isLoading = false;
    },
    handleRedirectDialogOk() {
      this.isRedirectDialogVisible = false;
      this.$router.replace("/login");
    },
  },
});
